import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
import Blockquote from '@components/molecules/Blockquote';
// import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
// import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';
import Column from '@components/organisms/Column';
// import Cta from '@components/molecules/Cta';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';

// --------------------------------
// #region data
// --------------------------------

const language = 'en';

const pageClass = 'page-project';

const accent_color = '#03A499';
const footerRelatedLinks = [
	{ title: 'MJF Swipe app', url: '/en/projects/montreux-jazz-festival-swipe-app/' },
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectMjfPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: { eq: "projects/rachis-clinic/rachis_clinic_hero.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: {
						eq: "projects/rachis-clinic/rachis_clinic_hero_mobile.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_1: file(
					relativePath: {
						eq: "projects/rachis-clinic/rachis_clinic_dna_en.png"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_2: file(
					relativePath: { eq: "projects/rachis-clinic/rachis_clinic_2.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_3: file(
					relativePath: { eq: "projects/rachis-clinic/rachis_clinic_3.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_4: file(
					relativePath: { eq: "projects/rachis-clinic/rachis_clinic_4.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_5: file(
					relativePath: { eq: "projects/rachis-clinic/rachis_clinic_5.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_6: file(
					relativePath: { eq: "projects/rachis-clinic/rachis_clinic_6.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/fr/projets/rachis-clinic/',
					lang: 'fr',
				},
			]}
			title="Rachis Clinic - By Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Work',
					link: '/en/projects/',
				}}
				title="Rachis Clinic"
				image={{
					...data.heroImage.childImageSharp,
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp
				}}
			/>
			<section className="grid grid-content" aria-label="Project">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Move again with serenity without suffering
							from back pain. Rachis Clinic, the specialist
							of the complex locomotive system.
						</p>

						<ListText
							className="element-spacing"
							title="Our services"
							list={[
								'Brand positioning',
								'Branding',
								'Visual identity',
								'Website'
							]}
						/>
					</div>
					<div className="project-description text-content">
						<p>
							The Rachis Clinic offers an interdisciplinary approach of ergotherapists,
							physiotherapists, back and complex musculoskeletal specialists. Its strength
							is accompanying you with the coordinated help of different specialists.
						</p>
						<h3>The challenge</h3>
						<p>
							As a newly created company, the Rachis Clinic had a clear and strong vision,
							but required in-depth work on positioning and support to define its future
							brand for its launch and development. The support included the Wellness &
							Rachis Program sub-brands.
						</p>

						<h3>Our solution</h3>
						<p>
							Driven by a series of workshops to define the brand's personality, positioning
							in relation to its market and audience as well as brand messages. The brand
							fully expresses its commitment to excellence while differentiating itself
							from the market with an avant-garde and eye-catching visual expression.
						</p>
					</div>
				</div>

				<Video
					className="col-full"
					src="dd88ef2d13442d76addcbe77df64f585"
					alt=""
				/>

				<Blockquote
					modifiers="big"
					uptitle=""
					quote={
						<>
							Regain your balance and the pleasure of moving on a daily basis.
						</>
					}
					more="While medicine mainly treats pain, the Rachis Clinic focuses on its root causes. Working on the balance of life, it applies a holistic approach to help remove back pain as an obstacle."
				/>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>
							Inspired by the professions of occupational and physical therapy
						</h3>
						<p>
							The branded visuals draw inspiration from the shapes of the equipment
							used for occupational and physical therapy exercises as well as certain
							shapes of bone structures.
						</p>
					</div>

					<div className="column__media text-content">
						<Video
							src="b585cd086d013e9614505f1105fc8923"
							alt=""
						/>
						<Video
							src="691c4b71276b2672267f90bad5f1c643"
							alt=""
						/>
					</div>
				</Column>

				<div className="box" data-animation-page>
					<div className="list-two-columns align-items-center justify-normal">
						<div className="text-content">
							<h3>Brand DNA</h3>
							<p>
								Defining a brand personality is central to positioning.
								We carried out workshops and identified brand tensions
								and terminologies that today define the specific brand DNA.
							</p>
						</div>
						<Image {...data.image_1.childImageSharp} alt="Logo" />
					</div>
				</div>

				<Image
					className="col-full"
					{...data.image_2.childImageSharp}
					alt="Typography"
				/>
				{/* 12 col + [Image + Image + Image + Image] + gutter */}
				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image
						{...data.image_3.childImageSharp}
						alt="Trame visuelle de la marque"
					/>
					<Image
						{...data.image_4.childImageSharp}
						alt="Symbole du sourire sur fond turquoise"
					/>
				</Gallery>

				<Column modifiers="left">
					<div className="column__media text-content">
						<Image
							{...data.image_5.childImageSharp}
							alt="Photo d'un médecin"
						/>
						<Image
							{...data.image_6.childImageSharp}
							alt="Photo d'une fiole"
						/>
					</div>
					<div className="column__text column__sticky text-content">
						<h3>
							Captivating and avant-garde
						</h3>
						<p>
							The visual expression captivates the attention with graphics
							in differentiation from the medical field. While expressing the
							avant-garde DNA of the clinic's approach.
						</p>
					</div>
				</Column>

				<Blockquote
					uptitle="Client testimony"
					quote="« The workshops and the positioning have enabled us to structure our company, and the DNA has been perfectly translated into our brand. This facilitates recruitment in a tense market, but also our positioning as a clinic of excellence. »"
					cite="Damiano Salvi, CEO"
				/>
			</section>
		</Layout>
	);
};

export default ProjectMjfPage;
